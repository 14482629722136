import { render, staticRenderFns } from "./TrendingDeclining.vue?vue&type=template&id=4f03aa1f&scoped=true&"
import script from "./TrendingDeclining.vue?vue&type=script&lang=js&"
export * from "./TrendingDeclining.vue?vue&type=script&lang=js&"
import style0 from "./TrendingDeclining.scss?vue&type=style&index=0&id=4f03aa1f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f03aa1f",
  null
  
)

export default component.exports