<template>
  <div :class="['sunrise-sunset-main']">
    <div v-if="!noData">
    <ReportWithSliderVue :numReports="numReports">
      <div :class="['tile is-parent is-5 p-0', reportFullscreen.status && 'display-contents']">
          <div class="report-outside" :class="[reportFullscreen.status && 'p-0']">
            <div class="report-inside transparent">
              <HorizontalDotPlot />
            </div>
          </div>
      </div>
      <div :class="['tile is-parent is-vertical is-7 p-0', reportFullscreen.status && 'display-contents']">
        <div class="report-outside" :class="[reportFullscreen.status && 'p-0']">
          <div class="featured-topics-Parent report-inside transparent">
            <Wordcloud
            :cloudId="'word-cloud1'"
            :wordData="featuredTopicsData"
            :chartTitle="chartTitle"
            :chartInfo="chartInfo"
            :type="chartType" />
          </div>
        </div>
        <div class="report-outside" :class="[reportFullscreen.status && 'p-0']">
          <div class="comments-share report-inside transparent">
            <HorizontalGroupedBarChart
              :chartData="voeSharePerCompany" />
          </div>
        </div>
      </div>
    </ReportWithSliderVue>
    </div>
    <div class="warning-tile" v-else>
    <no-data-container-vue type="sunset-sunrise" v-if="noData" />
    </div>
  </div>
</template>

<script>
import HorizontalDotPlot from '../../../components/SunriseSunset/HorizontalDotPlot'
import Wordcloud from '../../../components/SunriseSunset/WordCloud'
import HorizontalGroupedBarChart from '../../../components/SunriseSunset/HorizontalGroupedBarChart.vue'
import ReportWithSliderVue from '../../../components/Report/ReportWithSlider.vue'
import { mapActions, mapState } from 'vuex'
import NoDataContainerVue from '../../../components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle } from '../../../constant/data'

/** This is the parent component/view for the **Trending and Declining Drivers** page. */
export default {
  name: 'TrendingDeclining',
  metaInfo () {
    return {
      title: metaTitle.voeTrends
    }
  },
  components: {
    HorizontalDotPlot,
    ReportWithSliderVue,
    Wordcloud,
    HorizontalGroupedBarChart,
    NoDataContainerVue
  },
  data () {
    return {
      noData: true,
      observer: null,
      numReports: 3,
      chartTitle: 'Featured Topics',
      chartInfo: 'Word size indicates the relative increase or decline in mentions of the corresponding topic',
      chartType: 'emerging'
    }
  },
  computed: {
    ...mapState('filters', [
      'previousPeriodRange',
      'isPreviousPeriodInSubscription',
      'selectedCompaniesVoe',
      'selectedEmployeeStatuses',
      'ActiveVoeTimePeriod',
      'selectedBusinessFunc',
      'selectedSeniorityLevel'
    ]),
    ...mapState('sunriseSunset', ['jsonData', 'voeSharePerCompany', 'featuredTopicsData', 'selectedSSTopic', 'allTopics']),
    ...mapState('common', ['isCollapsed', 'reportFullscreen', 'currentModule']),
    watchable () {
      return JSON.stringify(this.selectedCompaniesVoe) + JSON.stringify(this.previousPeriodRange) + JSON.stringify(this.ActiveVoeTimePeriod) + JSON.stringify(this.isPreviousPeriodInSubscription) + JSON.stringify(this.selectedEmployeeStatuses) + JSON.stringify(this.selectedBusinessFunc) + JSON.stringify(this.selectedSeniorityLevel)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getData()
      }
    },
    jsonData () {
      if (!this.jsonData.length) {
        this.noData = true
      } else {
        this.noData = false
      }
    },
    // selectedCompaniesVoe () {
    //   this.getData()
    // },
    // previousPeriodRange () {
    //   this.getData()
    // },
    // ActiveVoeTimePeriod () {
    //   this.getData()
    // },
    // selectedEmployeeStatuses () {
    //   this.getData()
    // },
    isPreviousPeriodInSubscription () {
      if (this.isPreviousPeriodInSubscription === false) {
        this.noData = true
      } else {
        this.noData = false // add this condition in watchable property
        // this.getData()
      }
    },
    selectedSSTopic () {
      this.getRelativeData()
    },
    allTopics () {
      this.getRelativeData()
    }
  },
  created () {
    // this.getData()
  },
  mounted () {
    this.getData()
    if (this.isPreviousPeriodInSubscription === false) {
      this.noData = true
    } else {
      this.noData = false
    }
  },
  methods: {
    ...mapActions('sunriseSunset', [
      'getDotPlotData',
      'getVoESharePerCompanyData',
      'getFeaturedTopicsData'
    ]),
    /**
     * This method gets the data for the charts on the Trending and Declining drivers page
     * @public
     */
    getData () {
      if (
        this.selectedCompaniesVoe.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.ActiveVoeTimePeriod &&
        this.previousPeriodRange.length &&
        this.isPreviousPeriodInSubscription
      ) {
        const data = {
          module: this.currentModule,
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          employeeStatus: this.selectedEmployeeStatuses.map(item => item.id),
          businessFunc: this.selectedBusinessFunc.map(item => `'${item.name}'`),
          seniorityLevel: this.selectedSeniorityLevel.map(item => `'${item.name}'`),
          companies: this.selectedCompaniesVoe.map((item) => item.id),
          previousPeriodRange: this.previousPeriodRange
        }
        if (this.selectedSSTopic) {
          data.selectedSSTopic = this.selectedSSTopic
          data.is_standard = this.selectedSSTopic.is_standard
        }
        this.getDotPlotData(data)
      } else {
        this.noData = true
      }

      // this.checkDataSelection()
    },
    /**
     * This method gets the data for the previous time period
     * @public
     */
    getRelativeData () {
      if (
        this.selectedCompaniesVoe.length &&
        this.selectedEmployeeStatuses.length &&
        this.selectedBusinessFunc.length &&
        this.selectedSeniorityLevel.length &&
        this.ActiveVoeTimePeriod &&
        this.previousPeriodRange.length &&
        this.isPreviousPeriodInSubscription
      ) {
        const data = {
          module: this.currentModule,
          startTime: this.ActiveVoeTimePeriod.range[0],
          endTime: this.ActiveVoeTimePeriod.range[1],
          previousPeriodRange: this.previousPeriodRange,
          employeeStatus: this.selectedEmployeeStatuses.map(item => item.id),
          businessFunc: this.selectedBusinessFunc.map(item => `'${item.name}'`),
          seniorityLevel: this.selectedSeniorityLevel.map(item => `'${item.name}'`),
          allTopics: this.allTopics,
          companies: this.selectedCompaniesVoe.map((item) => item.id)
        }
        if (this.selectedSSTopic) {
          data.selectedSSTopic = this.selectedSSTopic
          data.is_standard = this.selectedSSTopic.is_standard
        }
        this.getVoESharePerCompanyData(data)
        this.getFeaturedTopicsData(data)
      } else {
        this.noData = true
      }
      // this.checkDataSelection()
    }
  }
}
</script>

<style lang="scss" src="./TrendingDeclining.scss" scoped />
